
import '@/assets/css/iframeStyle.scss';
import { Component, Vue, Watch } from 'vue-property-decorator';
import ContentTitle from "@/components/ContentTitle.vue";
import DialogTool from "./component/DialogTool.vue";
import { ElForm } from 'element-ui/types/form';

@Component({
    name: "Experience",
    components: {
        ContentTitle,
        DialogTool,
    },
})
export default class Experience extends Vue {
    private baseUrlIfram = process.env.VUE_APP_URL;
    private contentTitle: string = "我的体验";
    // DialogTool
    private tabFlag: boolean = false;
    private title: string = "";
    private content: string = "";
    private btnText: string = "";
    private rowData: object = {};
    // end
    private experienceData: any = {
        startTime: '',
        endTime: '',
    };
    private experienceRules: any = {
        startTime: [
            { type: 'date', required: true, message: '请选择日期', trigger: 'change' },
        ],
        endTime: [
            { type: 'date', required: true, message: '请选择日期', trigger: 'change' },
        ],
    };
    private experienceTab: any = [{
        number: '1',
        product: '生产报工管理系统',
        time: '2021-07-29',
        user: 'moreWis',
        type: '在线系统体验',
    }];
    private continueClick(row: any) {
        // this.tabToolData(row, "删除", "删除体验产品", "确定删除改体验产品？");
    }
    private tabToolData(rowData: any, btnText: string, title: string, content: string) {
        this.tabFlag = true;
        this.rowData = rowData;
        this.btnText = btnText;
        this.title = title;
        this.content = content;
    }
    private tabCloseMsg(num: number) {
        this.tabFlag = false;
    }
    private searchHandle(formName: any) {
        const elForm = this.$refs[formName]! as ElForm;
        elForm.validate((valid: boolean) => {
            if (valid) {
                //
            } else {
                return false;
            }
        });
    }
    private resetHandle(formName: any) {
        const elForm = this.$refs[formName]! as ElForm;
        elForm.resetFields();
    }
}
